import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const Privacy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Privacy Policy
        </Typography>
        
        <Paper elevation={1} sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" paragraph>
            Thank you for using our iOS applications. This Privacy Policy describes how we collect, use, and handle your information when you use our applications.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We may collect certain information automatically when you use our apps, including:
          </Typography>
          <ul>
            <Typography component="li" variant="body1">Device information (model, OS version)</Typography>
            <Typography component="li" variant="body1">App usage statistics</Typography>
            <Typography component="li" variant="body1">Crash reports</Typography>
          </ul>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use the collected information to:
          </Typography>
          <ul>
            <Typography component="li" variant="body1">Improve our applications</Typography>
            <Typography component="li" variant="body1">Fix bugs and issues</Typography>
            <Typography component="li" variant="body1">Provide customer support</Typography>
          </ul>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We take reasonable measures to protect your information from unauthorized access or disclosure. However, no method of transmission over the internet is 100% secure.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Third-Party Services
          </Typography>
          <Typography variant="body1" paragraph>
            Our applications may use third-party services that collect information. These services have their own privacy policies governing the use of information they collect.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us through our contact form.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Privacy; 