import React from 'react';
import {
  Container,
  Typography,
  Box,
  useTheme,
  Paper,
  Chip,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import { experiences, education } from '../data/portfolio';

const CV = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 } }}>
      {/* Header */}
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          mb: 6,
          textAlign: 'center',
          background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Experience & Education
      </Typography>

      {/* Timeline */}
      <Paper
        elevation={0}
        sx={{
          bgcolor: 'transparent',
          border: `1px solid ${theme.palette.primary.dark}30`,
          p: 4,
        }}
      >
        <Timeline position="alternate">
          {experiences.map((exp, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{
                  color: theme.palette.primary.light,
                  py: 4,
                }}
              >
                <Typography variant="h6" component="span">
                  {exp.year}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    bgcolor: theme.palette.primary.main,
                  }}
                >
                  <WorkIcon />
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: theme.palette.primary.main }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: 4 }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
                    border: `1px solid ${theme.palette.primary.dark}30`,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ color: theme.palette.primary.light }}
                  >
                    {exp.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {exp.company}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      my: 2,
                    }}
                  >
                    {exp.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {exp.technologies.map((tech, idx) => (
                      <Chip
                        key={idx}
                        label={tech}
                        size="small"
                        sx={{
                          bgcolor: `${theme.palette.primary.main}20`,
                          color: theme.palette.primary.light,
                        }}
                      />
                    ))}
                  </Box>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}

          {education.map((edu, index) => (
            <TimelineItem key={`edu-${index}`}>
              <TimelineOppositeContent
                sx={{
                  color: theme.palette.primary.light,
                  py: 4,
                }}
              >
                <Typography variant="h6" component="span">
                  {edu.year}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                  }}
                >
                  <SchoolIcon />
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: theme.palette.secondary.main }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: 4 }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
                    border: `1px solid ${theme.palette.primary.dark}30`,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ color: theme.palette.primary.light }}
                  >
                    {edu.degree}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    {edu.school}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      mt: 2,
                    }}
                  >
                    {edu.description}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Paper>
    </Container>
  );
};

export default CV; 