import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  Button,
  Box,
  useTheme,
  Chip,
  CardMedia,
  CardContent,
} from '@mui/material';
import { apps } from '../data/portfolio';

const Apps = () => {
  const theme = useTheme();
  const visibleApps = apps.filter(app => app.visible);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            mb: { xs: 6, md: 8 },
            textAlign: 'center',
            background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          My Apps
        </Typography>
        <Grid container spacing={4}>
          {visibleApps.map((app) => (
            <Grid item xs={12} md={6} key={app.id}>
              <Card
                sx={{
                  background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
                  border: `1px solid ${theme.palette.primary.dark}30`,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={app.image}
                  alt={app.title}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.primary.dark}30`,
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: theme.palette.primary.light }}
                  >
                    {app.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary',
                      mb: 2,
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {app.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 3 }}>
                    {app.technologies.map((tech, index) => (
                      <Chip
                        key={index}
                        label={tech}
                        size="small"
                        sx={{
                          bgcolor: `${theme.palette.primary.main}20`,
                          color: theme.palette.primary.light,
                        }}
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, mt: 'auto' }}>
                    <Button
                      component={RouterLink}
                      to={`/app/${app.id}`}
                      variant="outlined"
                      fullWidth
                      sx={{
                        borderColor: `${theme.palette.primary.main}50`,
                      }}
                    >
                      Learn More
                    </Button>
                    <Button
                      component="a"
                      href={app.appStoreLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="contained"
                      fullWidth
                    >
                      App Store
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Apps; 