// Personal Information
import app1Screenshot1 from '../assets/app1-1.png';

export const personalInfo = {
  name: 'Kirill Kholodilin',
  title: 'Builder',
  shortBio: 'I design and code beautifully simple things, and I love what I do.',
  fullBio: `Every great app begins with an even better story. Since beginning my journey as an iOS developer nearly 12 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use.

I'm quietly confident, naturally curious, and perpetually working on improving my skills one problem at a time.`,
  githubUrl: 'https://github.com/chrl',
  stats: {
    yearsExperience: 20,
    projectsCompleted: "50+",
  },
};

// Apps/Projects
export const apps = [
  {
    id: "marine-gps",
    title: 'Marine GPS',
    visible: true,
    description: 'Missing sailing instrument for smaller boats.',
    fullDescription: 'No built-in speedometer? No problem! Turn your phone into a reliable speed tracking device for any watercraft. Perfect for rental boats, kayaks, SUP boards, and small vessels without built-in instruments.\n' +
        '\n' +
        'Perfect for:\n' +
        '• Rental boats\n' +
        '• Kayaking\n' +
        '• Stand-up paddleboarding\n' +
        '• Small sailboats\n' +
        '• Fishing boats\n' +
        '• Personal watercraft\n' +
        '\n' +
        'Whether you\'re renting a boat for the day or enjoying your personal kayak, get instant speed readings without any extra equipment. The clean, intuitive interface provides essential information at a glance, making it ideal for watercraft that don\'t come with built-in instruments.\n',
    image: app1Screenshot1,
    appStoreLink: 'https://apps.apple.com/app/6739950204',
    technologies: ['Swift', 'SwiftUI', 'Location Data'],
    features: [
      'Simple, instant GPS speed tracking',
      'Works on any watercraft - from SUPs to rental boats',
      'Works offline - no internet required',
      'Support for knots, mph, and km/h',
      'High contrast display for excellent visibility in sunlight',
    ],
    version: '1.0.0',
    compatibility: 'iOS 14.0 or later',
    category: 'Navigation',
    screenshots: [
      app1Screenshot1,
      'https://via.placeholder.com/800x400',
      // Add more screenshots when available
    ],
    privacyPolicy: {
      lastUpdated: '2025-01-02',
      dataCollected: [
        {
          type: 'Location Data',
          purpose: 'To improve app functionality and user experience',
          storage: 'Stored locally on device',
        },
        {
          type: 'User Preferences',
          purpose: 'To save your app settings',
          storage: 'Stored locally on device',
        },
      ],
      thirdPartyServices: [
      ],
      dataRetention: 'All local data is deleted when you uninstall the app',
      contactEmail: 'charlie@chrl.ru',
    },
  },
  {
    id: 2,
    visible: false,
    title: 'Your App Name 2',
    description: 'Short description of your second app and its main features.',
    fullDescription: 'A detailed description of your second app and what problems it solves for users.',
    image: 'https://via.placeholder.com/600x400',
    appStoreLink: 'https://apps.apple.com/app/id2',
    technologies: ['UIKit', 'Core ML', 'ARKit'],
    features: [
      'Feature 1 description',
      'Feature 2 description',
      'Feature 3 description',
    ],
    version: '2.1.0',
    compatibility: 'iOS 13.0 or later',
    category: 'Lifestyle',
    screenshots: [
      'https://via.placeholder.com/800x400',
      'https://via.placeholder.com/800x400',
      'https://via.placeholder.com/800x400',
    ],
    privacyPolicy: {
      lastUpdated: '2024-01-02',
      dataCollected: [
        {
          type: 'Camera Access',
          purpose: 'Required for AR features',
          storage: 'Not stored, processed in real-time only',
        },
        {
          type: 'Location Data',
          purpose: 'To provide location-based features',
          storage: 'Stored locally and deleted after session',
        },
      ],
      thirdPartyServices: [
        {
          name: 'AR Kit',
          purpose: 'Augmented Reality features',
          dataAccessed: ['Camera feed during AR sessions'],
          privacyLink: 'https://developer.apple.com/privacy',
        },
      ],
      dataRetention: 'All data is temporary and deleted after each session',
      contactEmail: 'privacy@yourdomain.com',
    },
  },
];

// Work Experience
export const experiences = [
  {
    type: 'work',
    year: '2023 - Present',
    title: 'iOS Developer',
    company: 'Freelance',
    description: 'Working on various iOS projects as a freelance developer.',
    technologies: ['Swift', 'SwiftUI', 'UIKit', 'Core Data'],
  },
  {
    type: 'work',
    year: '2021 - 2023',
    title: 'iOS Developer',
    company: 'Yandex',
    description: 'Worked on Yandex Maps iOS application. Developed and maintained various features of the app, focusing on map-related functionality and user experience.',
    technologies: ['Swift', 'UIKit', 'MapKit', 'Core Location'],
  },
  {
    type: 'work',
    year: '2019 - 2021',
    title: 'iOS Developer',
    company: 'Sberbank',
    description: 'Developed features for SberBank Online iOS application, one of the largest banking apps in Russia. Worked on implementing new banking features and improving existing functionality.',
    technologies: ['Swift', 'UIKit', 'Core Data', 'Security frameworks'],
  },
  {
    type: 'work',
    year: '2018 - 2019',
    title: 'iOS Developer',
    company: 'Tinkoff',
    description: 'Part of the team developing Tinkoff mobile banking application. Focused on implementing new features and maintaining high code quality standards.',
    technologies: ['Swift', 'UIKit', 'RxSwift'],
  },
  {
    type: 'work',
    year: '2016 - 2018',
    title: 'iOS Developer',
    company: 'Raiffeisen Bank',
    description: 'Developed and maintained Raiffeisen Bank iOS application. Implemented key banking features and worked on improving app security.',
    technologies: ['Swift', 'Objective-C', 'UIKit'],
  },
  {
    type: 'work',
    year: '2014 - 2016',
    title: 'iOS Developer',
    company: 'Kaspersky Lab',
    description: 'Worked on Kaspersky security applications for iOS. Developed features focused on user security and privacy protection.',
    technologies: ['Objective-C', 'Security frameworks', 'UIKit'],
  },
];

// Education
export const education = [
  {
    year: '2009 - 2014',
    degree: 'Specialist degree in Computer Science',
    school: 'Bauman Moscow State Technical University',
    description: 'Specialized in Computer Software and Automated Systems. Focused on software engineering, algorithms, and system design.',
  },
]; 