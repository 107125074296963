import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import BusinessIcon from '@mui/icons-material/Business';
import { apps } from '../data/portfolio';
import ImageSlider from '../components/ImageSlider';

const AppDetails = () => {
  const { id } = useParams();
  const app = apps.find(app => app.id === id);

  if (!app) {
    return <Navigate to="/" replace />;
  }

  // Function to render text with newlines
  const renderWithNewlines = (text) => {
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        {/* App Title and Description */}
        <Typography variant="h3" component="h1" gutterBottom>
          {app.title}
        </Typography>
        <Typography 
          variant="h5" 
          color="text.secondary" 
          paragraph 
          sx={{ 
            whiteSpace: 'pre-line',
            mb: 4
          }}
        >
          {app.fullDescription}
        </Typography>

        {/* App Store Button */}
        <Box sx={{ mb: 6 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href={app.appStoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download on the App Store
          </Button>
        </Box>

        {/* App Info */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {/* Screenshots */}
            <Typography variant="h5" gutterBottom>
              Screenshots
            </Typography>
            <Box sx={{ mb: 6 }}>
              <ImageSlider images={app.screenshots} />
            </Box>

            {/* Features */}
            <Typography variant="h5" gutterBottom>
              Features
            </Typography>
            <List>
              {app.features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>

            {/* Privacy Policy */}
            <Box sx={{ mt: 6 }}>
              <Typography variant="h5" gutterBottom>
                Privacy Policy
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 3, display: 'block' }}>
                Last Updated: {app.privacyPolicy.lastUpdated}
              </Typography>

              {/* Data Collection */}
              <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <StorageIcon color="primary" /> Data Collection
                </Typography>
                <List>
                  {app.privacyPolicy.dataCollected.map((data, index) => (
                    <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant="subtitle1" color="primary.light">
                        {data.type}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Purpose: {data.purpose}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Storage: {data.storage}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Paper>

              {/* Third Party Services */}
              {app.privacyPolicy.thirdPartyServices?.length > 0 && (
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <BusinessIcon color="primary" /> Third Party Services
                  </Typography>
                  <List>
                    {app.privacyPolicy.thirdPartyServices.map((service, index) => (
                      <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="subtitle1" color="primary.light">
                          {service.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Purpose: {service.purpose}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Data Accessed: {service.dataAccessed.join(', ')}
                        </Typography>
                        <Button
                          href={service.privacyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="small"
                          sx={{ mt: 1 }}
                        >
                          Privacy Policy
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}

              {/* Data Retention */}
              <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SecurityIcon color="primary" /> Data Retention & Contact
                </Typography>
                <Typography variant="body2" paragraph color="text.secondary">
                  {app.privacyPolicy.dataRetention}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  For privacy-related inquiries, please contact: {app.privacyPolicy.contactEmail}
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* App Details Sidebar */}
            <Paper elevation={1} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                App Information
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Version"
                    secondary={app.version}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Compatibility"
                    secondary={app.compatibility}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Category"
                    secondary={app.category}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AppDetails; 