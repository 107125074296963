import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  Button,
  Box,
  useTheme,
  Chip,
  CardMedia,
  CardContent,
} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { personalInfo, apps } from '../data/portfolio';

const Home = () => {
  const theme = useTheme();

  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          pt: { xs: 8, md: 12 },
          pb: { xs: 8, md: 12 },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 700,
                  mb: 2,
                  background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {personalInfo.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  mb: 4,
                  fontSize: '1.1rem',
                }}
              >
                {personalInfo.shortBio}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={RouterLink}
                  to="/contact"
                  startIcon={<EmailIcon />}
                >
                  Let's Chat!
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<GitHubIcon />}
                  href={personalInfo.githubUrl}
                  target="_blank"
                  sx={{
                    borderColor: `${theme.palette.primary.main}50`,
                  }}
                >
                  GitHub
                </Button>
              </Box>

              {/* Stats */}
              <Grid container spacing={4} sx={{ mt: 6 }}>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.primary.light,
                    }}
                  >
                    {personalInfo.stats.yearsExperience}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                  >
                    YEARS<br />EXPERIENCE
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.primary.light,
                    }}
                  >
                    {personalInfo.stats.projectsCompleted}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                  >
                    PROJECTS COMPLETED<br />OR IN PROGRESS
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Introduction Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 } }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Hello! I'm {personalInfo.name.split(' ')[0]}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                mb: 4,
                lineHeight: 1.8,
              }}
            >
              {personalInfo.fullBio}
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Apps Section */}
      <Container maxWidth="lg" sx={{ pb: { xs: 8, md: 12 } }}>
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            mb: { xs: 6, md: 8 },
            textAlign: 'center',
            background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Latest Works
        </Typography>
        <Grid container spacing={4}>
          {apps.filter(app => app.visible).map((app) => (
            <Grid item xs={12} md={4} key={app.id}>
              <Card
                sx={{
                  background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
                  border: `1px solid ${theme.palette.primary.dark}30`,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={app.image}
                  alt={app.title}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.primary.dark}30`,
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: theme.palette.primary.light }}
                  >
                    {app.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      mb: 2,
                    }}
                  >
                    {app.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                    {app.technologies.map((tech, index) => (
                      <Chip
                        key={index}
                        label={tech}
                        size="small"
                        sx={{
                          bgcolor: `${theme.palette.primary.main}20`,
                          color: theme.palette.primary.light,
                        }}
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, mt: 'auto' }}>
                    <Button
                      component={RouterLink}
                      to={`/app/${app.id}`}
                      variant="outlined"
                      fullWidth
                      sx={{
                        borderColor: `${theme.palette.primary.main}50`,
                      }}
                    >
                      Learn More
                    </Button>
                    <Button
                      component="a"
                      href={app.appStoreLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="contained"
                      fullWidth
                    >
                      App Store
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Home; 