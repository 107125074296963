import React from 'react';
import Slider from 'react-slick';
import { Box, useTheme } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = ({ images }) => {
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    customPaging: (i) => (
      <Box
        sx={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
          opacity: 0.5,
          transition: 'all 0.3s ease',
          '&:hover': {
            opacity: 0.8,
          },
        }}
      />
    ),
  };

  return (
    <Box
      sx={{
        '.slick-dots': {
          bottom: '-40px',
          li: {
            margin: '0 6px',
            button: {
              '&:before': {
                display: 'none',
              },
            },
            '&.slick-active': {
              button: {
                div: {
                  opacity: 1,
                  transform: 'scale(1.3)',
                },
              },
            },
          },
        },
        '.slick-slide': {
          padding: '0 2px',
        },
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              paddingTop: '56.25%', // 16:9 aspect ratio
              borderRadius: 2,
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <Box
              component="img"
              src={image}
              alt={`Screenshot ${index + 1}`}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease',
                borderRadius: 2,
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlider; 